var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        width: _vm.width,
        visible: _vm.visible,
        confirmLoading: _vm.confirmLoading,
        destroyOnClose: true,
        cancelText: "关闭"
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel }
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.confirmLoading } },
        [
          _c(
            "a-form-model",
            {
              ref: "form",
              attrs: { model: _vm.model, rules: _vm.validatorRules }
            },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "父级节点",
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    prop: "pid"
                  }
                },
                [
                  _c("j-tree-select", {
                    ref: "treeSelect",
                    attrs: {
                      placeholder: "请选择父级节点",
                      dict: "sys_category,name,id",
                      pidField: "pid",
                      pidValue: "0",
                      disabled: _vm.disabled
                    },
                    model: {
                      value: _vm.model.pid,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "pid", $$v)
                      },
                      expression: "model.pid"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "分类名称",
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    prop: "name"
                  }
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入分类名称" },
                    model: {
                      value: _vm.model.name,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "name", $$v)
                      },
                      expression: "model.name"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }