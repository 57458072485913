var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-spin",
    { attrs: { spinning: _vm.confirmLoading } },
    [
      _c(
        "j-form-container",
        { attrs: { disabled: _vm.formDisabled } },
        [
          _c(
            "a-form-model",
            {
              ref: "form",
              attrs: {
                slot: "detail",
                model: _vm.model,
                rules: _vm.validatorRules
              },
              slot: "detail"
            },
            [
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "设备名称",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "name"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入设备名称" },
                            model: {
                              value: _vm.model.name,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "name", $$v)
                              },
                              expression: "model.name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "设备分类编码",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "code"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入设备编号" },
                            model: {
                              value: _vm.model.code,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "code", $$v)
                              },
                              expression: "model.code"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "分类",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "category"
                          }
                        },
                        [
                          _c("j-dict-select-tag", {
                            attrs: {
                              type: "list",
                              dictCode: "assets_category",
                              placeholder: "请选择分类"
                            },
                            model: {
                              value: _vm.model.category,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "category", $$v)
                              },
                              expression: "model.category"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "状态",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "status"
                          }
                        },
                        [
                          _c("j-dict-select-tag", {
                            attrs: {
                              type: "radio",
                              dictCode: "status",
                              placeholder: "请选择状态"
                            },
                            model: {
                              value: _vm.model.status,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "status", $$v)
                              },
                              expression: "model.status"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "封面",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "coverUrl"
                          }
                        },
                        [
                          _c("j-image-upload", {
                            model: {
                              value: _vm.model.coverUrl,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "coverUrl", $$v)
                              },
                              expression: "model.coverUrl"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "备注",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "remark"
                          }
                        },
                        [
                          _c("a-textarea", {
                            attrs: { rows: "4", placeholder: "请输入备注" },
                            model: {
                              value: _vm.model.remark,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "remark", $$v)
                              },
                              expression: "model.remark"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-tabs",
        {
          on: { change: _vm.handleChangeTabs },
          model: {
            value: _vm.activeKey,
            callback: function($$v) {
              _vm.activeKey = $$v
            },
            expression: "activeKey"
          }
        },
        [
          _c(
            "a-tab-pane",
            {
              key: _vm.refKeys[0],
              attrs: { tab: "设备明细", forceRender: true }
            },
            [
              _c("j-vxe-table", {
                ref: _vm.refKeys[0],
                attrs: {
                  "keep-source": "",
                  loading: _vm.assetsInfoTable.loading,
                  columns: _vm.assetsInfoTable.columns,
                  dataSource: _vm.assetsInfoTable.dataSource,
                  maxHeight: 300,
                  disabled: _vm.formDisabled,
                  rowNumber: true,
                  rowSelection: true,
                  toolbar: true
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }